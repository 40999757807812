import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import MiddleSection from './MiddleSection/MiddleSection';
import OrderContext from 'contexts/OrderContext/OrderContext';
import EmptyCart from './EmptyCart/EmptyCart';
import Meta from 'styleguide/components/Meta/Meta';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Modal from 'styleguide/components/Modal/Modal';
import Button from 'styleguide/components/Button/Button';
import A from 'styleguide/components/Links/A';
import TempOrderTotalsContextContainer from 'contexts/TempOrderTotalsContext/TempOrderTotalsContextContainer';

const CartPage = () => {
  const { order } = React.useContext(OrderContext);
  const [showDeliveryModal, setShowDeliveryModal] = React.useState(false);

  return (
    <>
      <Meta
        title="Cart"
        description=""
        keywords=""
        robots={['noindex']}
        canonical="https://www.printivity.com/cart"
      />
      <TitleContainer data-cy="cartPageTitle" title="Your cart" />
      {!!order &&
        (order.lineItems.length === 0 ? (
          <>
            {/* @ts-ignore */}
            <EmptyCart orderNumber={order.number} />
          </>
        ) : (
          <TempOrderTotalsContextContainer>
            <MiddleSection />
          </TempOrderTotalsContextContainer>
        ))}
      <Footer />
      {showDeliveryModal && (
        <Modal className="!max-w-2xl" hideScroll shown onClose={() => setShowDeliveryModal(false)}>
          <div className="px-1 py-0 text-left sm:px-4">
            <div data-cy="cartMessageTitle" className="heading-bold-xs">
              A Message from Printivity
            </div>
            <div className="paragraph-desktop flex flex-col">
              <p className="mb-3 mt-6">
                <strong>
                  Over the last several months we’ve noticed that Covid-19 has impacted supply chains, all
                  major shipping carriers (UPS, FedEx, and USPS), and production schedules. Your order may
                  experience a delay, and delivery dates are estimated.
                </strong>
              </p>
              <p className="my-3">
                If you have an event or need your product to arrive by a particular date,{' '}
                <strong>
                  we strongly recommend that you choose an earlier delivery date to make sure you have a
                  buffer to allow for a possible delivery delay
                </strong>
                .
              </p>
              <p className="my-3">
                We are closely monitoring the situation, and we look forward to restoring our delivery date
                guarantee soon.
              </p>
              <div className="mt-3 text-xl md:pr-10">
                <strong>Have any questions? Our Customer Service team is ready to help!</strong>
              </div>
              <p className="mb-6 mt-1">
                <div>Give us a call at 877-649-5463</div>
                <div className="mt-1">Monday-Friday 6:30-5:30 PST</div>
                <div className="mt-1">
                  <A href="mailto: service@printivity.com" underline="none" color="blue">
                    service@printivity.com
                  </A>
                </div>
              </p>
              <Button
                size="md"
                className="md:self-end"
                type="button"
                color="blue"
                dataCy="cartMessageConfirmationBtn"
                onClick={() => setShowDeliveryModal(false)}
              >
                Got It!
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CartPage;
